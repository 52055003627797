<template>
  <v-chip
    :color="attributes.color"
    label
    :prepend-icon="attributes.icon"
    :size="size"
    >{{ label }}</v-chip
  >
</template>

<script lang="ts" setup>
const properties = defineProps<{
  public: boolean;
  size?: "small";
}>();

const label = computed(() => {
  return properties.public ? "Public" : "Private";
});

const attributes = computed<{ icon: string; color?: string }>(() => {
  return properties.public
    ? {
        icon: "ph:eye",
        color: "success",
      }
    : {
        icon: "ph:eye-slash",
      };
});
</script>
